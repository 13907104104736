import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
    [
      _c(VCardTitle, { staticClass: "mb-6" }, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(" " + _vm._s(_vm.getModalTitle()) + " "),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VForm,
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.checkForm()
                },
              },
            },
            [
              _c(VSelect, {
                attrs: {
                  "data-test": "Vehicle:AddStockToVehicleModal:SelectStock",
                  disabled: _vm.edit || _vm.addNewValueStock,
                  items: _vm.allStocks,
                  "item-value": "Id",
                  "item-text": "Name",
                  label:
                    _vm.vehicleId && _vm.stockEdit
                      ? _vm.stockName
                      : _vm.$t("stocks"),
                  rules: [_vm.required],
                },
                model: {
                  value: _vm.stockSelected,
                  callback: function ($$v) {
                    _vm.stockSelected = $$v
                  },
                  expression: "stockSelected",
                },
              }),
              _c(VSelect, {
                attrs: {
                  "data-test": "Vehicle:AddStockToVehicleModal:SelectCurrency",
                  rules: [_vm.required],
                  items: _vm.currencyOptions,
                  "item-text": "text",
                  "item-value": "value",
                  label: _vm.$t("currency"),
                  dense: "",
                },
                model: {
                  value: _vm.stockCurrency,
                  callback: function ($$v) {
                    _vm.stockCurrency = $$v
                  },
                  expression: "stockCurrency",
                },
              }),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: {
                  "data-test": "Vehicle:AddStockToVehicleModal:StockValue",
                  label: _vm.$tc("stock", 1),
                  type: "number",
                  rules: [_vm.required],
                },
                model: {
                  value: _vm.stockValue,
                  callback: function ($$v) {
                    _vm.stockValue = $$v
                  },
                  expression: "stockValue",
                },
              }),
              _c(
                "div",
                { staticClass: "round-date-container" },
                [
                  _c(
                    VMenu,
                    {
                      ref: "endMenuDateRef",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: ["##/##/####"],
                                          expression: "['##/##/####']",
                                        },
                                      ],
                                      staticClass: "rounds-filters-date",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        dense: "",
                                        "append-icon": "mdi-calendar",
                                        label: "Data",
                                        height: "34",
                                      },
                                      on: {
                                        click: function () {
                                          return _vm.closeAlert()
                                        },
                                      },
                                      model: {
                                        value: _vm.stockTypeDate,
                                        callback: function ($$v) {
                                          _vm.stockTypeDate = $$v
                                        },
                                        expression: "stockTypeDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.stockTypeCaptureEndMenuDate,
                        callback: function ($$v) {
                          _vm.stockTypeCaptureEndMenuDate = $$v
                        },
                        expression: "stockTypeCaptureEndMenuDate",
                      },
                    },
                    [
                      _c(VDatePicker, {
                        attrs: {
                          max: _vm.maxDatePicker,
                          locale: "pt-BR",
                          color: "primary",
                          min: "1850-01-01",
                        },
                        on: { change: _vm.saveStockTypeDate },
                        model: {
                          value: _vm.stockTypeCaptureDate,
                          callback: function ($$v) {
                            _vm.stockTypeCaptureDate = $$v
                          },
                          expression: "stockTypeCaptureDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(VCheckbox, {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              { staticStyle: { color: "var(--dark)" } },
                              [_vm._v(_vm._s(_vm.$t("use_stock")))]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.useValue,
                      callback: function ($$v) {
                        _vm.useValue = $$v
                      },
                      expression: "useValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                { staticClass: "pl-0 dxa_modal_actions" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        "data-test":
                          "Vehicle:AddStockToVehicleModal:BtnSaveStock",
                        color: "primary",
                        "min-width": "100",
                        loading: _vm.loading,
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close", null)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.alert.show
            ? _c(VAlert, { attrs: { type: "error" } }, [
                _vm._v(" " + _vm._s(_vm.alert.message) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }